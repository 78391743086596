import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./components/home";
import Terms from "./components/terms";
import Area from "./components/area";
import Privacy from "./components/privacy";
import DataDeletionPolicy from "./components/dataDeletionPolicy";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Home />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/service-areas" element={<Area />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-of-use" element={<Terms />} />
        <Route path="/privacy-policy-cookie" element={<Privacy />} />
        <Route path="/deletion-policy" element={<DataDeletionPolicy />} />
      </Routes>
    </div>
  );
}

export default App;
