import React,{useEffect} from 'react'
import '../css/privacy.css'
import Header from './header';
import Footer from './footer';
export default function Privacy() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (<>

    <div className="PrivacyContainer">
    <Header />
    <div className="PrivacyContainerDiv">
    <div className="PrivacyBox">
      <h1 className="PrivacyHeading">Privacy Policy</h1>
      <p className="PrivacyParagraph">We value the belief you place in us. That's why we insist upon the highest standards for secure transactions and customer information privacy. Please read the following statement to learn about our information gathering and dissemination practices.</p>
      <p className="PrivacyParagraph">This Privacy Policy is an electronic record in the form of an electronic contract formed under the Information Technology Act, 2000 and the rules made thereunder and the amended provisions pertaining to electronic documents/records in various statutes as amended by the Information Technology Act, 2000. This Privacy Policy does not require any physical, electronic, or digital signature.</p>
      <p className="PrivacyParagraph">MastBazaar.Com Pvt. Ltd is the licensed owner of the brand “MB Daily” and the website mastbazaar.com ("The Site / The Service"). The terms of this Privacy Policy will be effective upon your acceptance of the same (directly or indirectly in electronic form, by clicking on the I accept tab or by use of the website or by other means) and will govern the relationship between you and us for your use of the app & the service “Mastbazaar.com”, “Mast Bazaar Daily” also known as “MB Daily” or simply “Daily”.</p>
      <p className="PrivacyParagraph">Please read this Privacy Policy carefully. By using the Website, you indicate that you understand, agree, and consent to this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use this app & the associated services.</p>
      <p className="PrivacyParagraph">By providing us your Information or by making use of the facilities provided by the app & the associated services; You hereby consent to the collection, storage, processing, and transfer of any or all of Your Personal Information and Non-Personal Information by us as specified under this Privacy Policy. You further agree that such collection, use, storage, and transfer of Your Information shall not cause any loss or wrongful gain to you or any other person.</p>
      <p className="PrivacyParagraph">However, the internet is an ever-evolving medium & therefore we reserve the right to amend our Privacy Policy from time to time to incorporate necessary future changes. Of course, our use of any information we gather will always be consistent with the policy under which the information was collected, regardless of what the new policy may be.</p>
      <h2 className="PrivacySub-heading">Collection of User Information</h2>
      <p className="PrivacyParagraph">To avail certain services on our Websites, users are required to provide certain information for the registration process, namely, Your name, email address, sex, age, postal code of your residences, billing addresses & delivery addresses, credit card, debit card details, bank account, mobile wallet information, medical records and history, sexual orientation, biometric information, password, etc., and/or your occupation, interests, and the like. The Information as supplied by the users enables us to improve our sites and provide you the most user-friendly experience.</p>
      <p className="PrivacyParagraph">All required information is service-dependent, and we may use the above-said user information to maintain, protect, and improve its services (including advertising services) and for developing new services.</p>
      <p className="PrivacyParagraph">Such information will not be considered sensitive if it is freely available and accessible in the public domain or is furnished under the Right to Information Act, 2005, or any other law for the time being in force.</p>
      <h2 className="PrivacySub-heading">Cookies</h2>
      <p className="PrivacyParagraph">To improve the responsiveness of the sites for our users, we may use "cookies" or similar electronic tools to collect information to assign each visitor a unique, random number as a User Identification (User ID) to understand the user's individual interests using the Identified Computer. Unless you voluntarily identify yourself (through registration, for example), we will have no way of knowing who you are, even if we assign a cookie to your computer. The only personal information a cookie can contain is information you supply. A cookie cannot read data off your hard drive. Our advertisers may also assign their cookies to your browser (if you click on their ads), a process that we do not control.</p>
      <p className="PrivacyParagraph">Our web servers automatically collect limited information about your computer's connection to the Internet, including your IP address when you visit our site. (Your IP address is a number that lets computers attached to the Internet know where to send you data -- such as the web pages you view.) Your IP address does not identify you personally. We use this information to deliver our web pages to you upon request, to tailor our site to the interests of our users, to measure traffic within our site, and let advertisers know the geographic locations from where our visitors come.</p>
      <h2 className="PrivacySub-heading">Information Sharing</h2>
      <p className="PrivacyParagraph">We do not share sensitive personal information with any third party without obtaining the prior consent of the user in the following limited circumstances when it is requested or required by law or by any court or governmental agency or authority to disclose, for the purpose of verification of identity, or for the prevention, detection, investigation including cyber incidents, or for prosecution and punishment of offenses. These disclosures are made in good faith and belief that such disclosure is reasonably necessary for enforcing these Terms; for complying with the applicable laws and regulations.</p>
      <p className="PrivacyParagraph">We propose to share such information within its group companies and officers and employees of such group companies for the purpose of processing personal information on its behalf. We also ensure that these recipients of such information agree to process such information based on our instructions and in compliance with this Privacy Policy and any other appropriate confidentiality and security measures.</p>
      <h2 className="PrivacySub-heading">Information Security</h2>
      <p className="PrivacyParagraph">We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure, or destruction of data. These include internal reviews of our data collection, storage, and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data.</p>
      <p className="PrivacyParagraph">All information gathered on our Website is securely stored within our controlled database. The database is stored on servers secured behind a firewall; access to the servers is password-protected and is strictly limited. However, as effective as our security measures are, no security system is impenetrable. We cannot guarantee the security of our database, nor can we guarantee that information you supply will not be intercepted while being transmitted to us over the Internet. And, of course, any information you include in a posting to the discussion areas is available to anyone with Internet access.</p>
      <h2 className="PrivacySub-heading">Grievance Officer</h2>
      <p className="PrivacyParagraph">In accordance with the Information Technology Act 2000 and rules made thereunder, the contact details of the Grievance Officer & our support team are provided below:</p>
      <p className="PrivacyAddress">
        C1401, Happiness Towers<br></br>
        Pacifica Aurum, Padur, Chennai – 603103<br></br>
        Phone: +91 806 945 1366<br></br>
        Email: hello@mastbazaar.com<br></br>
        Time: Monday – Friday (10 AM – 6 PM Indian Standard Time)
      </p>
    </div>
    </div>
  </div>
  <Footer/>
  </>
  )
}

