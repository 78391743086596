import React, { useEffect } from "react";
import "../css/terms.css";
import Header from "./header";
import Footer from "./footer";

const DataDeletionPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="termsContainer">
        <Header />
        <div className="container4">
          <div className="termsContent">
            <div
              style={{
                margin: "20px",
                lineHeight: 1.6,
                color: "#333",
              }}
            >
              <h1 style={{ color: "#2c3e50", fontSize: "24px" }}>
                MB Daily Data Deletion Policy
              </h1>

              <p>
                At MB Daily, we prioritize the privacy and security of our
                users' data. This Data Deletion Policy outlines the procedures
                and guidelines for the deletion of user data collected through
                our app.
              </p>

              <h2
                style={{
                  color: "#2c3e50",
                  fontSize: "20px",
                  marginTop: "20px",
                }}
              >
                1. Introduction
              </h2>
              <p>
                This Data Deletion Policy explains how we manage the deletion of
                user data upon request and the timeframe in which the process
                will be completed.
              </p>

              <h2
                style={{
                  color: "#2c3e50",
                  fontSize: "20px",
                  marginTop: "20px",
                }}
              >
                2. Scope
              </h2>
              <p>
                This policy applies to all user data collected, processed, and
                stored by MB Daily through our app.
              </p>

              <h2
                style={{
                  color: "#2c3e50",
                  fontSize: "20px",
                  marginTop: "20px",
                }}
              >
                3. Types of Data Collected
              </h2>
              <p>
                We collect various types of data to provide a personalized and
                seamless experience for our users. This may include:
              </p>
              <ul style={{ margin: "10px 0", paddingLeft: "20px" }}>
                <li>
                  <strong>Personal Information:</strong> Name, email address,
                  phone number, etc.
                </li>
                <li>
                  <strong>Usage Data:</strong> Interactions with the app,
                  preferences, search history, etc.
                </li>
                <li>
                  <strong>Location Data:</strong> GPS coordinates for
                  location-based features.
                </li>
                <li>
                  <strong>Transaction Data:</strong> Information related to
                  purchases and transactions.
                </li>
              </ul>

              <h2
                style={{
                  color: "#2c3e50",
                  fontSize: "20px",
                  marginTop: "20px",
                }}
              >
                4. Data Deletion Requests
              </h2>
              <p>
                Users have the right to request the deletion of their data. To
                initiate a data deletion request, users need to follow these
                steps:
              </p>
              <ul style={{ margin: "10px 0", paddingLeft: "20px" }}>
                <li>Login to your MB Daily account.</li>
                <li>Go to the Profile page.</li>
                <li>
                  Under the "Customer Support" section, click on "Send Message".
                </li>
                <li>Type your request to delete your account and hit enter.</li>
              </ul>

              <h2
                style={{
                  color: "#2c3e50",
                  fontSize: "20px",
                  marginTop: "20px",
                }}
              >
                5. Deletion Process
              </h2>
              <p>
                Upon receiving a valid data deletion request, we will promptly
                review and authenticate the request. Once confirmed, the
                following steps will be taken:
              </p>
              <ul style={{ margin: "10px 0", paddingLeft: "20px" }}>
                <li>
                  <strong>Personal Information:</strong> Erased from our
                  database.
                </li>
                <li>
                  <strong>Usage Data:</strong> Anonymized or permanently
                  deleted.
                </li>
                <li>
                  <strong>Transaction Data:</strong> Retained only for legal or
                  accounting purposes.
                </li>
              </ul>

              <h2
                style={{
                  color: "#2c3e50",
                  fontSize: "20px",
                  marginTop: "20px",
                }}
              >
                6. Timeframe for Deletion
              </h2>
              <p>
                We will make reasonable efforts to process data deletion
                requests within 6 working hours from the time of the request. If
                there is any wallet balance in the user's account, it will be
                credited to the source payment method within 15 working days.
              </p>

              <div style={{ marginTop: "30px" }}>
                <h2 style={{ color: "#2c3e50", fontSize: "20px" }}>
                  Contact Us
                </h2>
                <p>
                  If you have any questions or need further assistance, please
                  contact our support team at{" "}
                  <a href="tel:+918069451366">+918069451366</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default DataDeletionPolicy;
