// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/Fonts/Poppins-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("assets/Fonts/Poppins-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("assets/Fonts/Poppins-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("assets/Fonts/Poppins-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("assets/Fonts/Poppins-BoldItalic.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  margin: 0;\n \n  /*-webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale; */\n}\n\n\nbody::-webkit-scrollbar {\n  display: none;\n}\n\n@font-face {\n  font-family: Poppins-Bold  ;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n  font-family: Poppins-Medium  ;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@font-face {\n  font-family: Poppins-Regular  ;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n@font-face {\n  font-family: Poppins-Light  ;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n@font-face {\n  font-family: Poppins-Bold-Italic  ;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n}", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;;EAET;uCACqC;AACvC;;;AAGA;EACE,aAAa;AACf;;AAEA;EACE,2BAA2B;EAC3B,4CAAuC;AACzC;AACA;EACE,6BAA6B;EAC7B,4CAAyC;AAC3C;AACA;EACE,8BAA8B;EAC9B,4CAA0C;AAC5C;AACA;EACE,4BAA4B;EAC5B,4CAAwC;AAC1C;AACA;EACE,kCAAkC;EAClC,4CAA6C;AAC/C","sourcesContent":["body {\n  margin: 0;\n \n  /*-webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale; */\n}\n\n\nbody::-webkit-scrollbar {\n  display: none;\n}\n\n@font-face {\n  font-family: Poppins-Bold  ;\n  src: url(assets/Fonts/Poppins-Bold.ttf);\n}\n@font-face {\n  font-family: Poppins-Medium  ;\n  src: url(assets/Fonts/Poppins-Medium.ttf);\n}\n@font-face {\n  font-family: Poppins-Regular  ;\n  src: url(assets/Fonts/Poppins-Regular.ttf);\n}\n@font-face {\n  font-family: Poppins-Light  ;\n  src: url(assets/Fonts/Poppins-Light.ttf);\n}\n@font-face {\n  font-family: Poppins-Bold-Italic  ;\n  src: url(assets/Fonts/Poppins-BoldItalic.ttf);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
