import React from 'react'
import '../css/home.css'
import { Link } from "react-router-dom";
import Logo from '../assets/Logo.svg'
export default function Footer() {
    return (
        <>
        <div className="footer">
            <div className="contact">
                <p>Contact Support <a href="tel:+918069451366"> +91 806 945 1366</a></p>
                <p>Time: Monday – Friday (10 AM – 6 PM IST)</p>

            </div>
            <div className="footerLogo">
                <img src={Logo} />
                <p className="rights">2023, Mast Bazaar. All rights reserved</p>
            </div>
            <div className="foterNav">
                <p> <Link to="/privacy-policy">Privacy Policy</Link></p>
                <p> <Link to="/terms-and-conditions" >Terms & Conditions</Link></p>
            </div>
        </div >
        <div className="mFooter">
        <Link to="/" ><img src={Logo} className="mLogo" /></Link>
          <p> <Link to="/privacy-policy" >Privacy Policy</Link></p>
          <p> <Link to="/terms-and-conditions" >Terms & Conditions</Link> </p>
          <p>Contact Support <a href="tel:+918069451366"><br></br> +91 806 945 1366</a><br></br>Time: Monday – Friday (10 AM – 6 PM IST)</p>
          <p className="rights">2023, Mast Bazaar. All rights reserved</p>
        </div>
        </>
    )
}
