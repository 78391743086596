import React from 'react'
import '../css/home.css'
import { Link } from "react-router-dom";
import headBg from '../assets/WhiteBG_Top.svg'
import Logo from '../assets/Logo.svg'
import leftArrow from '../assets/Icon_Caret.svg'
export default function Header() {
    return (
        <>
            <div className="head">
                <img src={headBg} alt="" className="headBg" />
                <div className="headNav">
                <Link to="/" ><img src={Logo} className="Logo" /></Link>
                    <p className="margin"><a href='/#Cart' >Subscriptions</a></p>
                    <img src={leftArrow} className="leftArrow margin" />
                    <p className="margin"><a href='/#DO'>Delivery Options</a></p>
                    <img src={leftArrow} className="leftArrow margin" />
                    <p className="margin"><Link to="/service-areas" >Areas we Serve</Link></p>
                </div>
            </div>
            <div className="mhead">
                <div className="mheadNav">
                <a href="/" ><img src={Logo} className="mLogo" /></a>
                </div>
            </div>
        </>
    )
}
