import React, { useEffect, useState } from 'react'
import '../css/area.css';
import Header from './header';
import Footer from './footer';
export default function Area() {
    useEffect(() => {
        window.location.replace('https://areas.mastbazaar.com')
    });

    const areas = [
        "Akshaya Adena, Padur, 603103",
        "Akshaya Adora, Padur, 603103",
        "Akshaya January, Kelambakkam, 603103",
        "Akshaya Today, Kelambakkam, 603103",
        "Alliance Garden Front, Thaiyur, 603103",
        "Alliance Humming Gardens, Kelambakkam, 603103",
        "Arihant Frangipani, Siruseri, 603103",
        "Aurum Villas, Padur, 603103",
        "Casagrand Southbrooke, Kelambakkam, 603103",
        "Creations Valencia, Egattur, 603103",
        "Elysium RWD, Kelambakkam, 603103",
        "EPA Pacific City Apartment, Kelambakkam, 603103",
        "Freedom by Provident, Pudupakkam, 603103",
        "Godrej Azure, Padur, 603103",
        "Happiness Towers, Padur, 603103",
        "Indus Anantya, Egattur, 603103",
        "Jains Inseli Park, Padur, 603103",
        "Janani Blue Bells, Padur, 603103",
        "Mantri Synergy, Padur, 603103",
        "Neelkamal Apartments, Kazhipattur, 603103",
        "Olympia Opaline, Navalur, 603103",
        "PBEL City, Kelambakkam, 603103",
        "Pragnya Eden Park, Siruseri, 603103",
        "Pride Towers, Padur, 603103",
        "Purva Swanlake, Padur, 603103",
        "Ramaniyam's Rythmm, Navalur, 603103",
        "Sobha Meritta, Kelambakkam, 603103",
        "Srikaram Shubhadhi, Thaiyur, 603103",
        "Sri Sreenivasa Maple Wood, Kazhipattur, 603103",
        "TVH Ouranya Bay, Padur, 603103",
        "TVH Taus, Egattur, 603103",
        "The Gem Grove Apartments, Padur, 603103",
        "Vishram Oasis, Thazambur, 603103",
        "Victoria Towers, Kazhipattur, 603103",
        "XS Real Amity, Padur, 603103",
        "XS Real Harmony, Padur, 603103",
        "XS Real Helios City, Padur, 603103",
        "XS Real Siena, Padur, 603103",
        "XS Real Symphony, Padur, 603103"
    ];

    const [searchText, setSearchText] = useState('');

    const filteredAreas = areas.filter(area => area.toLowerCase().includes(searchText.toLowerCase()));

    return (
        <>
           
          {/*  <div className="Areacontainer">
            <Header />
                <div className="Areacontent-container">
                    <div className="Areacontent">
                        <div className="AreacontentDiv">
                        <h1 className="AreaTittle">Areas we Serve</h1>
                        <input
                            type="text"
                            placeholder="Search for an area, society or pincode"
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                        />
                        <ul id="AreaareaList" className="">
                            {filteredAreas.map((area, index) => (
                                <li key={index} className="areaItem">
                                    {area}
                                </li>
                            ))}
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
                            <Footer/>*/}
        </>
    );
};
